<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="$parent.back" >返回</el-button>
				</p>
				<div class="h-b">
					<el-button
						type="text"
						size="small"
						@click="save"
						>保存</el-button>
				</div>
			</div>
		</div>

		<div>
			<el-form
				:model="dataSource"
				ref="_sysConfigForm"
				:rules="sysConfigCheckRule"
			>
				<el-tabs type="border-card" v-model.trim="activeName" class="tab-container">
					<el-tab-pane label="资源配置" name="tab1">
						<!-- <el-tree :data="dataSource.EnterpriseMenuTree"
                            show-checkbox
                            node-key="id"
                            ref="_EnterpriseTree"
                            :default-checked-keys="checked_keys"
                            highlight-current>
                		</el-tree> -->
						<div class="h-table-list menulist">
							<div class="h-scroll">
								<div class="table-s">
									<el-table border highlight-current-row :default-expand-all='isExpand' ref="tableMenu" row-key="MenuId"
										:tree-props="{ children: 'Children', hasChildren: 'hasChildren' }"
										style="width: 100%;margin-bottom: 20px;" :data="menuDataSource || []" 
										@select="tableSelect"
										@select-all="tableSelectAll"
										@selection-change="selectionChange">
										<el-table-column label="菜单/权限">
											<template slot-scope="scope">
												<span>{{ scope.row['MenuName'] }}</span>
											</template>
										</el-table-column>
										<el-table-column label="菜单类型">
											<template slot-scope="scope">
												<span class="appCls" v-if="scope.row['MenuBusinessType']==100">应用</span>
												<span class="menuCls" v-else-if="scope.row['MenuBusinessType']==200">菜单</span>
												<span class="permCls" v-else>权限</span>
											</template>
										</el-table-column>
										<el-table-column label="自定义名">
											<template slot-scope="scope">
												<el-input v-model="scope.row['MenuName']" />
											</template>
										</el-table-column>
										<el-table-column type="selection" width="55"></el-table-column>
									</el-table>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="商品字段配置" name="tab2">
						<div class="h-table-list">
							<div class="h-scroll">
								<div class="table-s">
									<div class="table-button-left">
										<el-form-item>
											<GridDialog
											    label="添加"
												dataTitle="商品扩展字段"
												dataUrl='omsapi/sysindustry/getmetafieldlist?businessType=1'
												dataMethod="get"
												:isPage=false
												@syncFieldEvent="syncProductFieldHandle"
											></GridDialog>
											<el-button @click.native="fieldClear(1)">清空</el-button>
										</el-form-item>
									</div>
									<el-form-item>
										<el-table :data="dataSource.ProductFieldList" border style="width: 100%">
											<el-table-column prop="FieldName" label="物理字段">
												<template slot-scope="scope">
													<span>{{scope.row['FieldName']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldLabel" label="中文名称">
												<template slot-scope="scope">
													<span>{{scope.row['FieldLabel']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldBizType" label="业务类型">
												<template slot-scope="scope">
													<span>{{scope.row['FieldBizType']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldType" label="字段类型">
												<template slot-scope="scope">
													<span>{{scope.row['FieldType']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="Visible" label="是否显示">
												<template slot-scope="scope">
													<el-switch v-model="scope.row['Visible']"
														active-text=""
														inactive-text="">
													</el-switch>
												</template>
											</el-table-column>
											<el-table-column prop="Required" label="是否必填">
												<template slot-scope="scope">
													<el-switch v-model="scope.row['Required']"
														active-text=""
														inactive-text="">
													</el-switch>
												</template>
											</el-table-column>
											<el-table-column prop="FieldSort" label="排序">
												<template slot-scope="scope">
													<el-input v-model="scope.row['FieldSort']"></el-input>
												</template>
											</el-table-column>
											<el-table-column label="操作" width="100">
												<template slot-scope="scope">
													<el-button type="primary" size="small" style="margin-left:10px;" @click="dataSource.ProductFieldList.splice(scope.$index, 1)">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="订单字段配置" name="tab3">
						<div class="h-table-list">
							<div class="h-scroll">
								<div class="table-s">
									<div class="table-button-left">
										<el-form-item>
											<GridDialog
											    label="添加"
												dataTitle="订单扩展字段"
												dataUrl='omsapi/sysindustry/getmetafieldlist?businessType=2'
												dataMethod="get"
												:isPage=false
												@syncFieldEvent="syncOrderFieldHandle"
											></GridDialog>
											<el-button @click.native="fieldClear(2)">清空</el-button>
										</el-form-item>
									</div>
									<el-form-item>
										<el-table :data="dataSource.OrderFieldList" border style="width: 100%">
											<!-- <el-table-column width="55" type="selection"></el-table-column> -->
											<el-table-column prop="FieldName" label="物理字段">
												<template slot-scope="scope">
													<span>{{scope.row['FieldName']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldLabel" label="中文名称">
												<template slot-scope="scope">
													<span>{{scope.row['FieldLabel']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldBizType" label="业务类型">
												<template slot-scope="scope">
													<span>{{scope.row['FieldBizType']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="FieldType" label="字段类型">
												<template slot-scope="scope">
													<span>{{scope.row['FieldType']}}</span>
												</template>
											</el-table-column>
											<el-table-column prop="Visible" label="是否显示">
												<template slot-scope="scope">
													<el-switch v-model="scope.row['Visible']"
														active-text=""
														inactive-text="">
													</el-switch>
												</template>
											</el-table-column>
											<el-table-column prop="FieldSort" label="排序">
												<template slot-scope="scope">
													<el-input v-model="scope.row['FieldSort']"></el-input>
												</template>
											</el-table-column>
											<el-table-column label="操作" width="100">
												<template slot-scope="scope">
													<el-button type="primary" size="small" style="margin-left:10px;" @click="dataSource.OrderFieldList.splice(scope.$index, 1)">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-form-item>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeName:'tab1',
			isExpand:false,
			checked_keys:[],
			isDisabled: false,
			sysConfigCheckRule: {
				//ConfigMemo: [{ required: true, message: '请输入配置描述', trigger: 'blur' }]
			},
			dataSource:{},
			menuQueryParam: {
				PageIndex: 1,
				PageSize: 10
			},
			menuDataSource:[],
			theadData:[],
			multipleSelection: [],
		}
	},
	props: {
		config: {
			isDetailDisplay: false,
			dataSourceKey:null
		}
	},
	mounted() {
		this.Event.$on('clearEditSysConfigForm', () => this.resetForm);
		//this.getList();
	},
	watch: {
		'config.dataSourceKey':{
			handler(curVal, oldVal) {
				if(curVal&&curVal>0){
					if(curVal!=oldVal) 
						this.initial(curVal); //初始化
				}
			},
			deep: true
		},
		menuDataSource: {
			handler(curVal, oldVal) {
			},
			deep: true,
		},
	},
	methods: {
		resetForm() {
			!this.isPassValidate && this.$refs['_sysConfigForm'].resetFields() //清空表单
		},
		initial(id){
			var _this = this;
			_this.$ajax.send("omsapi/sysindustry/get", "get", { id: id }, (data) => {
				_this.dataSource=data.Result;
				_this.menuDataSource = data.Result.MenuList;
				_this.$nextTick(() => {
					_this.initialTable();
				})
			});
		},
		save() {
			var _this = this
			_this.$refs['_sysConfigForm'].validate((valid) => {
				_this.isPassValidate = valid;
				var editSource = _this.dataSource;
				editSource.MenuList = _this.menuDataSource;
				if (valid) {
					_this.$ajax.send('omsapi/sysindustry/save', 'post', editSource, (data) => {
							_this.Event.$emit('reloadPageList', data.Result);
							_this.Utils.messageBox('保存成功.', 'success');
						}
					)
				} else {
					return false
				}
			})
		},
		getList() {
            var _this = this;
            _this.$ajax.query("omsapi/sysindustry/getmenulist", "post", _this.menuQueryParam, (data) => {
                _this.menuDataSource = data;
				_this.$nextTick(() => {
					_this.initialTable();
				})
            });
        },
		setChildren (children, type) {
			// 编辑多个子层级
			children.map((j) => {
				this.toggleSelection(j, type);
				j.IsVisible = type;
				if (j.Children && j.Children.length > 0) {
				this.setChildren(j.Children, type)
				}
			})
		},
		toggleSelection (row, select) {
			if (row) {
				this.$nextTick(() => {
				this.$refs.tableMenu && this.$refs.tableMenu.toggleRowSelection(row, select)
				})
			}
		},
		tableSelect (selection, row) {
			if (selection.some((el) => { return row.MenuId === el.MenuId })) {
				row.IsVisible = true;
				if (row.Children) {
				this.setChildren(row.Children, true)
				}
			} else {
				row.IsVisible = false;
				if (row.Children) {
				this.setChildren(row.Children, false)
				}
			}
		},
		tableSelectAll (selection) {
			// tabledata第一层只要有在selection里面就是全选
			var data = this.$refs.tableMenu.data;
			const isSelect = selection.some((el) => {
				const tableDataIds = data.map((j) => j.MenuId)
				return tableDataIds.includes(el.MenuId)
			})
			// tableDate第一层只要有不在selection里面就是全不选
			const isCancel = !data.every((el) => {
				const selectIds = selection.map((j) => j.MenuId)
				return selectIds.includes(el.MenuId)
			})
			if (isSelect) {
				selection.map((el) => {
					el.IsVisible = true;
					if (el.Children) {
						// 解决子组件没有被勾选到
						this.setChildren(el.Children, true)
					}
				})
			}
			if (isCancel) {
				data.map((el) => {
					el.IsVisible = false;
					if (el.Children) {
						// 解决子组件没有被勾选到
						this.setChildren(el.Children, false)
					}
				})
			}
		},
		selectionChange (selection) {
			this.multipleSelection = selection;
			this.$emit('selectionChange', selection);
		},
		initialTable(){
			var data = this.$refs.tableMenu.data;
			data.map((el) => {
				if(el.IsVisible)
					this.$refs.tableMenu.toggleRowSelection(el, el.IsVisible);
				if (el.Children) {
					this.initialChild(el.Children)
				}
			})
		},
		initialChild(list){
			list.map((el) => {
				if(el.IsVisible)
					this.$refs.tableMenu.toggleRowSelection(el, el.IsVisible);
				if (el.Children) {
					this.initialChild(el.Children)
				}
			})
		},
		syncProductFieldHandle(row,list){
			var index = this.dataSource.ProductFieldList.length;
			if(list && list.length>0){
				list.forEach(item => {
					item.Visible = true;
					item.Required = false;
					item.FieldSort = 1+index++;
					this.dataSource.ProductFieldList.push(item);
				});
			}
		},
		syncOrderFieldHandle(row,list){
			var index = this.dataSource.OrderFieldList.length;
			if(list && list.length>0){
				list.forEach(item => {
					item.Visible = true;
					item.FieldSort = 1+index++;
					this.dataSource.OrderFieldList.push(item);
				});
			}
		},
		fieldClear(type){
			if(type==1){
				this.dataSource.ProductFieldList=[];
			}else{
				this.dataSource.OrderFieldList=[];
			}
		}
	},
}
</script>
<style>
  .appCls{background-color: #169bd5; color: #ffffff; padding: 10px; border-radius: 12px;}
  .menuCls{background-color: #4bc1f3; color: #ffffff; padding: 10px; border-radius: 12px;}
  .permCls{background-color: #f59a23; color: #ffffff; padding: 10px; border-radius: 12px;}
  .h-table-list.menulist .el-table td.el-table_1_column_1{ text-align: left !important;}
</style>
